import * as React from 'react'
import { Masthead } from "../components/layout/masthead"
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { Layout } from "../components/global/layout"

const PreviewPage = () => {
  return (
    <Layout
        pageTitle="Preview"
        metaTitle="Preview"
        ogType="website">
        <Masthead title="Preview" />
        <div className="c-content">
            <div className="container">
                <div className="row">
                    <div className="c-content__container cms-content">
                        <p>Loading preview...</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default withPrismicPreviewResolver(PreviewPage, repositoryConfigs)